import { countries } from 'countries-list';
import countries3to2 from 'countries-list/dist/countries3to2.json';

export const scrollToEl = (el, offset = 150) => {
    const elRect = el.getBoundingClientRect();
    setTimeout(() => {
        window.scrollTo({
            top: window.pageYOffset + elRect.y - offset,
            left: 0,
            behavior: 'smooth'
        });
    }, 50)
}

export const scrollToError = (target, offset) => {
    let firstInputError = target.querySelector('.is-invalid');
    if (!firstInputError)
        firstInputError = target.querySelector('.has-error');
    if (firstInputError)
        scrollToEl(firstInputError, offset)
}

export const isCountryInContinents = (countryCode3, continents = []) => {
    const countryCode2 = countries3to2[countryCode3] || null;

    if(!countryCode2)
        return false;

    const country = countries[countryCode2] || null;

    return country && continents.indexOf(country.continent) > -1;
}
